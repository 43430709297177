import React from "react";
import ProcessStep from "./ProcessStep";
import TeamSection from "./TeamSection";
import "./styles.css";
import { useMiscStore } from "../../state/store";
import { colors } from "../../Utils/colors";
import { useMediaQuery } from "react-responsive";

interface AuthenticationProcessProps {}

const auth2 = require("../../images/auth_2.jpg");
const auth3 = require("../../images/auth_3.jpg");
const auth4 = require("../../images/auth_4.jpg");
const auth5 = require("../../images/auth_5.jpg");
const auth6 = require("../../images/auth_6.jpg");

const AuthenticationProcess: React.FC<AuthenticationProcessProps> = () => {
    const getThemeColor = useMiscStore((state) => state.getThemeColor);
    const backgroundColor = getThemeColor({
        light: colors.GREY_1,
        dark: colors.DARKGREY_16,
    });
    const textColor = getThemeColor({
        light: colors.DARKGREY_16,
        dark: colors.GREY_0,
    });
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

    const steps = [
        {
            title: `1. Initial Inspection and Unboxing`,
            description: `It's not just a box! Real collectors care, so we place just as much quality control on the box and accessories as we do the sneaker during the initial examination. If an item is damaged in transit we contact all parties to get ahead of any necessary claims.`,
            imageSrc: auth2,
            imageAlt: `Initial inspection and unboxing process`,
            leftAlignedImage: isTabletOrMobile || false,
        },
        {
            title: `2. Detailed Manual Authentication`,
            description: `At RTRO, we believe in full transparency throughout the authentication process. Unlike other services, we don't just provide a simple pass/fail result. Our team meticulously documents every aspect of the sneaker, including any quality control issues, no matter how small. As we conduct our assessment, you receive detailed images and information in real-time through the RTRO app.`,
            imageSrc: auth3,
            imageAlt: `Detailed manual authentication process`,
            leftAlignedImage: isTabletOrMobile || true,
        },
        {
            title: `3. Multi-Point Physical Inspection`,
            description: `Our experts perform an extensive 20 point inspection, by hand, to ensure the highest level of authenticity and quality control in the industry. At RTRO, our authentication team's unparalleled expertise sets the gold standard in sneaker verification. Led by industry veterans with nearly two decades of experience, our team includes former leaders from sneaker giants like Flight Club, GOAT, and NiceKicks. Our experts have handled countless rare and valuable sneakers, giving them an eagle eye for both authenticity and quality issues. We don't just spot fakes; we provide a comprehensive assessment that serious collectors can trust, ensuring every transaction is backed by the industry's most seasoned professionals.`,
            imageSrc: auth4,
            imageAlt: `Multi-point physical inspection process`,
            leftAlignedImage: isTabletOrMobile || false,
        },
        {
            title: `4. Quality Control Assessment`,
            description: `In recent years, accelerated production schedules and expanded manufacturing operations have presented unprecedented challenges for serious collectors. From misaligned stitching to color inconsistencies and material flaws, these quality control issues are now frequent considerations in the collecting process. These defects can significantly impact a sneaker's value and desirability, making expert authentication and detailed quality assessment more crucial than ever. At RTRO, we understand the gravity of these issues and are committed to providing collectors with the comprehensive information they need to make informed decisions in this evolving landscape.`,
            imageSrc: auth5,
            imageAlt: `Quality control assessment process`,
            leftAlignedImage: isTabletOrMobile || true,
        },
        {
            title: `5. Final Verification and Detailed Report`,
            description: `After thorough inspection, we conduct a final review where authenticated sneakers receive our RTRO verification. Have a question about a particular detail? Our knowledgeable customer service agents - true sneaker experts - are available to chat with you live, providing insights on everything from glue stains and stitching imperfections to color variations that serious collectors care about. This level of transparency and expert interaction ensures you have all the information you need to make informed decisions about your sneakers`,
            imageSrc: auth6,
            imageAlt: `Final verification and detailed report process`,
            leftAlignedImage: isTabletOrMobile || false,
        },
    ];

    return (
        <section className="authentication-process" style={{ backgroundColor }}>
            <h1 className="section-title" style={{ color: textColor }}>
                Our Authentication Process
            </h1>
            <p className="section-description" style={{ color: textColor }}>
                At RTRO, we go <strong>beyond</strong> just verifying
                authenticity. As passionate sneaker collectors ourselves, we
                understand the needs, wants, and problems that arise when
                buying, selling, or trading.
                <br />
                <br />
                Our expert team obsesses over the details that matter to serious
                sneaker collectors like you. Here's how we ensure an industry
                leading service at scale:
            </p>
            {steps.map((step, index) => (
                <ProcessStep key={index} {...step} />
            ))}
            <TeamSection />
        </section>
    );
};

export default AuthenticationProcess;
