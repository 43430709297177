import { useMediaQuery } from "react-responsive";
import { useMiscStore } from "../../state/store";
import { colors } from "../../Utils/colors";

const ryanImg = require("../../images/team-ryan.png");
const colinImg = require("../../images/team-colin.png");
const aislanImg = require("../../images/team-aislan.png");
const breinerImg = require("../../images/team-breiner.png");
const bryceImg = require("../../images/team-bryce.png");
const vincentImg = require("../../images/team-vincent.png");
const nickImg = require("../../images/team-nick.png");
const georgeImg = require("../../images/team-george.png");
const melImg = require("../../images/team-melvin.png");
const kodyImg = require("../../images/team-kody.png");

const team = [
    {
        name: `Ryan Schiller`,
        title: `CEO`,
        image: ryanImg,
    },
    {
        name: `Colin Roberts`,
        title: `CTO`,
        image: colinImg,
    },
    {
        name: `Vincent Bezares`,
        title: `COO`,
        image: vincentImg,
    },
    {
        name: `Aislan Lander`,
        title: `SENIOR ENGINEER`,
        image: aislanImg,
    },
    {
        name: `Bryce Adams`,
        title: `SENIOR ENGINEER`,
        image: bryceImg,
    },
    {
        name: `Breiner Leal`,
        title: `PRODUCT DESIGN`,
        image: breinerImg,
    },
    {
        name: `Nick Mendez`,
        title: `HEAD OF SNEAKERS`,
        image: nickImg,
    },
    {
        name: `George Coles`,
        title: `AUTHENTICATION LEAD`,
        image: georgeImg,
    },
    {
        name: `Mel Nunez`,
        title: `HEAD OF AUTHENTICATION`,
        image: melImg,
    },
    {
        name: `Kody Lamb`,
        title: `COMMUNITY DEVELOPMENT`,
        image: kodyImg,
    },
];

export default function TeamSection() {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    const getThemeColor = useMiscStore((state) => state.getThemeColor);

    const {
        containerStyle,
        titleStyle,
        memberContainerStyle,
        memberStyle,
        memberImageStyle,
        memberNameStyle,
        memberTitleStyle,
    } = styles(colors, getThemeColor, isTabletOrMobile);

    return (
        <div style={containerStyle}>
            <h2 style={titleStyle}>OUR TEAM</h2>
            <div style={memberContainerStyle}>
                {team.map((member, i) => (
                    <div key={i} style={memberStyle}>
                        <img
                            src={member.image}
                            alt={member.name}
                            style={memberImageStyle}
                        />
                        <div>
                            <div style={memberNameStyle}>{member.name}</div>
                            <div style={memberTitleStyle}>{member.title}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

const styles = (
    colors: any,
    getThemeColor: any,
    isTabletOrMobile: boolean,
) => ({
    containerStyle: {
        margin: "auto",
        width: "90%",
        maxWidth: 1200,
        marginBottom: 80,
    } as React.CSSProperties,
    titleStyle: {
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
        fontSize: isTabletOrMobile ? 28 : 56,
        fontWeight: 700,
        letterSpacing: "5.6px",
        marginBottom: 50,
    } as React.CSSProperties,
    memberContainerStyle: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        gap: 50,
    } as React.CSSProperties,
    memberStyle: {
        flex: `1 0 40%`,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 24,
    } as React.CSSProperties,
    memberImageStyle: {
        width: isTabletOrMobile ? 60 : 100,
        height: isTabletOrMobile ? 60 : 100,
        borderRadius: 100,
    } as React.CSSProperties,
    memberNameStyle: {
        color: getThemeColor({
            light: colors.DARKGREY_16,
            dark: colors.GREY_0,
        }),
        fontSize: 28,
        fontWeight: 700,
        letterSpacing: "-0.84px",
        marginBottom: 8,
    } as React.CSSProperties,
    memberTitleStyle: {
        color: colors.INDIGO,
        fontSize: 14,
        fontWeight: 600,
        letterSpacing: "3.08px",
        textTransform: "uppercase",
    } as React.CSSProperties,
});
